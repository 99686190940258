require("lightgallery.js");

// function $$(q, c) {return Array.prototype.slice.call((c||document).querySelectorAll(q));}

const galleries = [...document.querySelectorAll('.section--gallery .section_items')];

galleries.forEach(function(obj, index) {
    lightGallery(obj);
});

document.querySelector('.menu-button').addEventListener('click', function(e) {
    e.preventDefault();
    document.documentElement.classList.toggle('menu--active');
});

document.querySelector('.menu-overlay').addEventListener('click', function(e) {
    e.preventDefault();
    document.documentElement.classList.toggle('menu--active');
});

const anchorlinks = [...document.querySelectorAll('[data-scroll]')];

anchorlinks.forEach(function(obj, index) {
    obj.addEventListener('click', function(e) {
       e.preventDefault();
       var anchor = obj.getAttribute('data-scroll').replace("#", "");
       var target = document.getElementById(anchor);
       target.scrollIntoView({ behavior: 'smooth' })
       // var target_pos = el.getBoundingClientRect().top + document.body.scrollTop;

    });
});
